import React, { useEffect, useState } from "react";
import Employee from "./Employee";
import axios from "axios";
import "../../css/Employees.css";
import * as Constants from "../constants";

function EmployeeManagement(props) {
  const [employeeList, SetEmployeeList] = useState([]);
  const [permissionList, SetPermissionList] = useState([]);
  const [skillsList, SetSkillsList] = useState([]);
  const [filterByList, SetFilterByList] = useState([]);
  const [filteredEmployeesList, SetFilteredEmployeesList] = useState([]);

  function GetEmployees() {
    axios
      .get(Constants.jobApiUrl + "GetEmployees", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetEmployeeList(res.data);
      });
  }

  function GetPermissions() {
    axios
      .get(Constants.jobApiUrl + "GetPermissions", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetPermissionList(res.data);
      });
  }

  function GetSkills() {
    axios
      .get(Constants.jobApiUrl + "GetSkills", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetSkillsList(res.data);
      });
  }

  function UpdateFilter(state, id) {
    if (!state) {
      SetFilterByList(filterByList.filter((item) => item !== id));
    } else {
      SetFilterByList([...filterByList, id]);
    }
  }

  function UpdateShownEmployees() {
    let newFilteredEmployeesList = employeeList.slice();
    if (filterByList.length > 0) {
      newFilteredEmployeesList = newFilteredEmployeesList.filter((employee) =>
        filterByList.every((filterId) =>
          employee.skills.some((s) => s.id === filterId)
        )
      );
    }
    SetFilteredEmployeesList(newFilteredEmployeesList);
  }

  useEffect(() => {
    UpdateShownEmployees();
  }, [filterByList, employeeList]);

  useEffect(() => {
    GetEmployees();
    GetPermissions();
    GetSkills();
  }, []);

  return (
    <>
      <div className="employeePageHeader flexRow">Employee Management</div>
      <div className="mainEmployeePage">
        <div className="employeePageFilterBy flexColumn">
          Filter By
          <div className="employeePageFilterByList">
            {skillsList.map((s) => (
              <div className="filterByItem flexRow leftAlignContent">
                <input
                  type="checkbox"
                  className="filterCheckbox"
                  onChange={(e) => UpdateFilter(e.target.checked, s.id)}
                ></input>
                {s.name}
              </div>
            ))}
          </div>
        </div>
        <div className="employeeList flexColumn fullWidth">
          <div className="employeeHeader fullwidth">
            <h2 className="textLeft">Name</h2>
            <h2>Certifications</h2>
            <h2 className="textLeft">Role</h2>
          </div>
          <div className="employeeListInner flexColumn fullWidth">
            {filteredEmployeesList.map((e) => (
              <Employee
                key={e.id}
                employee={e}
                RefreshEmployees={GetEmployees}
                permissions={permissionList}
                user={props.user}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeManagement;
