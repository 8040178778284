import React from "react";
import LoginButton from "./LoginButton";
import HeaderButton from "./HeaderButton";
import HeaderLogo from "./HeaderLogo";
import NotificationsButton from "./NotificationsButton";

function Header(props) {
  return (
    <div className="header flexRow">
      <HeaderLogo />
      <div className="headerButtons flexRow">
        <HeaderButton linkTo="/" buttonText="Schedule" />
        <HeaderButton linkTo="/Employees" buttonText="Employees" />
        <HeaderButton linkTo="/Equipment" buttonText="Equipment" />
        {/* <HeaderButton linkTo="/Time" buttonText="Time" /> */}
      </div>
      <div className="loginButton flexRow">
        <NotificationsButton
          user={props.user}
          RefreshUserInfo={props.RefreshUserInfo}
          ShowModal={props.ShowModal}
          ShowModal2={props.ShowModal2}
          modal2State={props.showModal2}
          dropDownData={props.dropDownData}
        />
        <LoginButton buttonText="Microsoft User" />
      </div>
    </div>
  );
}

export default Header;
