import "./css/App.css";
import "./css/normalize.css";
import Base from "./Components/Base/Base.js";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import * as Constants from "./Components/constants";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const { instance } = useMsal();
  const msal = useMsal();
  const [user, SetUser] = useState();

  function RedirectIfNotLoggedIn() {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  function GetUserInfo() {
    if (msal.accounts[0] != null) {
      if (
        Constants.AllowedTenants.includes(msal.accounts[0].idTokenClaims.tid)
      ) {
        if (user == null) {
          Constants.ShowLoading();
        }
        axios
          .get(Constants.jobApiUrl + "GetLoggedInEmployee", {
            params: {
              code: Constants.APIKey,
              email:
                msal.accounts[0].username.includes("@hammrtech.com") &&
                msal.accounts[0].username !== "william.guthe@hammrtech.com" &&
                msal.accounts[0].username !== "joseph.roy@hammrtech.com"
                  ? "skidabrader@hammrtech.com"
                  : msal.accounts[0].username,
            },
          })
          .then((res) => {
            if (user == null) {
              Constants.HideLoading();
            }
            SetUser(res.data);
          })
          .catch((err) => {
            console.log(err);
            if (user == null) {
              Constants.HideLoading();
            }
          });
      }
    }
  }

  useEffect(() => {
    GetUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msal.accounts[0]]);

  useEffect(() => {
    const rebuildTooltips = setInterval(() => {
      ReactTooltip.rebuild();
    }, 2 * 1000);
    const refreshUserData = setInterval(() => {
      GetUserInfo();
    }, 60 * 1000);
    return () => {
      clearInterval(rebuildTooltips);
      clearInterval(refreshUserData);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App flexRow">
      <div id="loadingScreen" className="loadingScreen displayNone flexColumn">
        <div className="loadingAnimation"></div>
        <div className="loadingText">Loading...</div>
      </div>
      <ReactTooltip multiline={true} />
      <AuthenticatedTemplate>
        {user != null &&
        user.employeeInfo.permissions.includes("Project Manager") ? (
          <Base user={user} RefreshUserInfo={GetUserInfo} />
        ) : (
          <div className="signInButton flexColumn fullWidth fullHeight">
            Your account does not have access to this resource or no internet
            connection is available. Please contact your administrator for
            assistance.
          </div>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="signInButton flexColumn">
          <img
            src="/images/SKIDABRADER_LOGO%2520HORIZONTAL_edited.webp"
            alt="Skidabrader Logo"
          />
          <button onClick={RedirectIfNotLoggedIn}>Please Sign In</button>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
