import React, { useState } from "react";
import axios from "axios";
import * as Constants from "../../constants";

function NotificationItem(props) {
  function UpdateRead() {
    var formData = new FormData();
    formData.append("messageId", props.message.id);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "UpdateReadMessage?code=" + Constants.APIKey,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    }).then((res) => {
      props.RefreshUserInfo();
    });
  }

  function GoToJob() {
    props.ShowJobModal(props.message.data.jobId);
    UpdateRead();
  }

  return (
    <div className="notificationsItem">
      {props.message.message}
      <div className="notificationButton flexRow pointer" onClick={UpdateRead}>
        Mark Read
      </div>
      <div className="notificationButton flexRow pointer" onClick={GoToJob}>
        Go To Job
      </div>
    </div>
  );
}

export default NotificationItem;
